.loaderContainer {
  height: 100vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  /* background-color: red; */
  align-self:center
}