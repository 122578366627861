
main {
  padding: 2rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 4px dotted red;  */ 
}



.fontbasic {
  font-size: 20px;
}

.fontheaders {
  font-size: 24px;
}

@media(max-width: 900px) {
  .fontbasic {
    font-size: 12px;
  }
  
  .fontheaders {
    font-size: 16px;
  }
}



.form-header {
  font-size: calc(10px + 3vmin);
  word-wrap: break-word;
}

.form-int {
  word-wrap: break-word;
}

.form-data {
  text-align: left;
}


.form-projects {
  margin: 0;
  margin-top: 5px;
}



.sendBtn {
  float: left;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #2B4BF2;
  color: #fafafa;
  font-size: 12px;
  border: 0px;
}

.sendBtn:hover {background-color: #2d61e4}

.sendBtn:active {
  background-color: #000;
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}



/* TEXTINPUT */
.form-textinput {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #2B4BF2;
  border-radius: 8px;
  background: rgba(123, 151, 206, 0.06);  color: #000;
  font-size: 12px;
  box-sizing: border-box;
}
.form-inputlabel {
  text-align: left;
  font-size: 12px;
  display: flex;
  margin-left: 5px;
}

.form-big {
min-height: 10rem;
}


/* CHECKBOX */
.checkboxContainer {
 /*  border: 4px dotted red;   */
}

.checkboxHeader {
  text-align: left;
 /*  margin-top: 2.5rem;
  margin-bottom: 0.9rem; */
}

.checkboxItems {
  /* border: 4px dotted red;   */
  display: flex;
  justify-content: space-between;
}



.checkboxItems label {
  vertical-align: middle;
  margin-left: 0.5vw;
}

[type=checkbox] {
  width: 1.3vmax;
  height: 1.3vmax;
  color: #2B4BF2  ;
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 50%;
  transition: background 300ms;
  cursor: pointer;
  background: url('../../assets/images/cb.png');
  background-repeat: no-repeat;
  background-size: cover;
}


/* Checked */
[type=checkbox]:checked {
  background: url('../../assets/images/cb-checked.png');
  background-repeat: no-repeat;
  background-size: cover;
}




.swalCustom {
  font-family: "DM Sans Bold" !important;
}


.swal2-popup {
  font-family: "DM Sans Bold" !important;

}